<template>
  <div class="col-sm-6 col-md-4 menucard">
    <router-link :to="route" class="d-flex flex-column align-items-center">
      <h2 class="menucard__title mb-4"> {{ title }} </h2>
      <img class="menucard__img" :src="require('@/assets/icons/' + icon_name + '')" alt="Card image">
    </router-link>
  </div>
</template>

<script>
export default {
  name: "MenuCard",
  props: ['title', 'icon_name', 'route']
}
</script>

<style>
/* TODO: Remove in the future if svg is not needed /*
svg {
  fill: #333;
  transition: fill .3s ease;
  width: 50%
}
svg:hover {
  fill: #f6931d;
}
/* ------------------------------------------------ */

.menucard {
  max-width: 350px;
  height: 16rem;
  text-align: center;
  margin-bottom: 3rem;
}

.menucard a {
  text-decoration: none;
}

.menucard__title {
  color: #333;
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration: none;

  transition: font-size .2s;
}

.menucard__img {
  width: 10rem;
  transition: width .2s;
}


/* Animations */
.menucard:hover .menucard__title {
  font-size: 1.6rem;
}

.menucard:hover .menucard__img {
  width: 11rem;
}
</style>
